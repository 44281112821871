import React from "react";

import "./ComicButton.css";

function ComicButton({text, onClick, className}) {
  return (
    <button className={`comic-button ${className}`} onClick={onClick}>
      {text}
    </button>
  );
}

export default ComicButton;