import { Button } from "./Button";
import { KSIcon } from "./Icons";
import { Container } from "./Container";
import CompMorkWhiteVert from "../images/compatibleBorgs/CompWith_MORKBORG_vert_white.png";
import CompCyWhiteVert from "../images/compatibleBorgs/CompWith_CY_BORG_vert_white.png";
import manhattanBridgeGreen from "../images/backgrounds/cover-wide.png";
import coverVignette from "../images/backgrounds/cover-vignette-only.png";
import manhole from "../images/decorations/manhole_backdrop.png";
import sewerScumText from "../images/decorations/sewer_scum_text.png";
import { LogoWhite } from "./Logo";

function PlayIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
      <circle cx="12" cy="12" r="11.5" stroke="#FFFFFF" />
      <path
        d="M9.5 14.382V9.618a.5.5 0 0 1 .724-.447l4.764 2.382a.5.5 0 0 1 0 .894l-4.764 2.382a.5.5 0 0 1-.724-.447Z"
        fill="#FFFFFF"
        stroke="#FFFFFF"
      />
    </svg>
  );
}

export function Hero() {
  return (
    <div
      className="relative overflow-hidden py-20 sm:py-32 lg:pb-32 xl:pb-36"
      style={{
        backgroundImage: `url(${manhattanBridgeGreen})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "#000000",
      }}
    >
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `url(${coverVignette})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          zIndex: 0,
        }}
      ></div>
      <Container>
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
          <div className="relative z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6 mt-10">
            <h1 className="text-4xl font-medium tracking-tight text-white permanent-marker">
              80s NYC MUTANT MADNESS
            </h1>
            <p className="mt-6 text-lg text-white">
            Explore SEWER SCUM, a gritty take on the fast-paced, gonzo action of Saturday morning cartoons and comics. Set in a dystopian, crime-infested 1980s New York City, you'll encounter mutant animals, ninjas, robots, aliens, and so much more.
            </p>
            <div className="mt-8 flex flex-wrap gap-x-6 gap-y-4">
              <Button
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                variant="solid"
                color="lime"
              >
                <KSIcon />
                <span className="ml-2.5 text-white">Back this project</span>
              </Button>
              <Button
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                variant="solid"
                color="lime"
              >
                <PlayIcon className="h-6 w-6 flex-none" />
                <span className="ml-2.5 text-white">Watch the video</span>
              </Button>
            </div>
          </div>
          <div className="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
            <div className="-mx-4 h-[448px] px-9 [mask-image:linear-gradient(to_bottom,white_60%,transparent)] sm:mx-0 lg:absolute lg:-inset-x-10 lg:-bottom-20 lg:-top-10 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-32">
              <img src={manhole} alt="Manhole" />
              <img
                src={sewerScumText}
                alt="Sewer Scum"
                className="absolute inset-0 z-20 lg:mt-[8rem]"
              />
            </div>
          </div>
          <div className="relative -mt-4 lg:col-span-7 xl:col-span-6">
            <ul
              role="list"
              className="mx-auto mt-4 flex max-w-xl justify-center gap-x-10 gap-y-8 lg:mx-0 lg:justify-start"
            >
              <img
                src={CompMorkWhiteVert}
                alt="Compatible with Mork Borg"
                className="h-32 mr-1"
              />
              <img
                src={CompCyWhiteVert}
                alt="Compatible with Cy_Borg"
                className="h-32 mr-1"
              />
              <LogoWhite className="mr-1 h-40 -mt-2" />
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
}
