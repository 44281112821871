import clsx from 'clsx';

const baseStyles = {
  solid:
    'inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors',
  outline:
    'inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors',
};

const variantStyles = {
  solid: {
    cyan: 'relative overflow-hidden bg-cyan-500 text-white before:absolute before:inset-0 active:before:bg-transparent hover:before:bg-white/10 active:bg-cyan-600 active:text-white/80 before:transition-colors',
    white:
      'bg-white text-cyan-900 hover:bg-white/90 active:bg-white/90 active:text-cyan-900/70',
    gray: 'bg-gray-800 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80',
    lime: 'bg-lime-700 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80',
  },
  outline: {
    gray: 'border-gray-300 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80',
  },
};

export function Button({ className, variant = 'solid', color = 'gray', href, ...props }) {
  className = clsx(
    baseStyles[variant],
    variant === 'outline' ? variantStyles.outline[color] : variantStyles.solid[color],
    className,
  );

  // Determine component type based on whether `href` is defined
  const Component = href ? 'a' : 'button';

  // Props to pass to the component
  const componentProps = {
    className,
    ...props,
  };

  // If `href` is defined, add additional attributes to open in new tab
  if (href) {
    componentProps.href = href;
    componentProps.target = '_blank';
    componentProps.rel = 'noopener noreferrer';
  }

  return <Component {...componentProps}>{props.children}</Component>;
}
