import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import "./fonts.css";
import SewerScumCharGen from "./Components/SewerScumCharGen/SewerScumCharGen";
import RootLayout from "./layout";
import Home from "./page";

export default function Temp() {
  return (
    <div className="flex items-center justify-center min-h-screen">
      COMING SOON
    </div>
  );
}

// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Temp />} />
//       </Routes>
//     </Router>
//   );
// };

const App = () => {
  return (
    <Router>
      <RootLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sewerscum" element={<Home />} />
          <Route path="/sewerscum/chargen" element={<SewerScumCharGen />} />
        </Routes>
      </RootLayout>
    </Router>
  );
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) for TypeScript

root.render(<App />);
