import React from "react";
import {
  containerTable,
  coolStuffTable,
  tubularStuffTable,
  petTable,
  instrumentTable,
  pizzaTable,
} from "../../tables/tables-obfu.min.js";

export function GearDisplay(textStyleClass, dayJobItems) {
  const containerValue = Math.floor(Math.random() * containerTable.length);
  const coolStuffValue = Math.floor(Math.random() * coolStuffTable.length);
  const tubularStuffValue = Math.floor(
    Math.random() * tubularStuffTable.length
  );
  const petValue = Math.floor(Math.random() * petTable.length);
  const instrumentValue = Math.floor(Math.random() * instrumentTable.length);
  const pizzaValue1 = Math.floor(Math.random() * pizzaTable.length);
  let pizzaValue2;
  do {
    pizzaValue2 = Math.floor(Math.random() * pizzaTable.length);
  } while (pizzaValue2 === pizzaValue1);
  const d3Value = Math.floor(Math.random() * 3) + 1;

  const container = containerTable[containerValue];
  const coolStuff = coolStuffTable[coolStuffValue];
  const tubularStuff = tubularStuffTable[tubularStuffValue];
  const pet = petTable[petValue];
  const instrument = instrumentTable[instrumentValue];
  const pizza1 = pizzaTable[pizzaValue1];
  const pizza2 = pizzaTable[pizzaValue2];

  return (
    <div>
      <div className={textStyleClass + " equipment"}>
        <div>-{container}</div>
        <div>
          {coolStuffValue !== 11 && "-" + coolStuff}
          {coolStuffValue === 11 && <span>-Free Pizza Coupon(s) ({d3Value})</span>}
        </div>
        <div>
          {!(
            tubularStuffValue === 9 ||
            tubularStuffValue === 10 ||
            tubularStuffValue === 11
          ) && "-" + tubularStuff}
          {tubularStuffValue === 9 && <span>-Grenade(s) ({d3Value})</span>}
          {tubularStuffValue === 10 && <span>-Instrument: {instrument}</span>}
          {tubularStuffValue === 11 && <span>-Pet: {pet}</span>}
        </div>
        <div>-{dayJobItems}</div>
        <div>-Water Bottle</div>
        <div>
          -Slice of {pizza1} and {pizza2} Pizza
        </div>
      </div>
    </div>
  );
}

export default GearDisplay;
