import React from "react";

export function WeaponDisplay(
  weapon,
  classValue,
  textStyleClass,
  positionStyleClass
) {
  return (
    <div className={textStyleClass + " weapon " + positionStyleClass}>
      {classValue === 4 /* ninja */ ? (
        <div>Melee Ninja Weapon 1h/2h [d6/d8] and Throwing Knives 1h [d4]</div>
      ) : (
        <div>{weapon}</div>
      )}
    </div>
  );
}

export default WeaponDisplay;
