import { Container } from "./Container";
import floppy from "../images/backgrounds/floppy.png";

const faqs = [
  [
    {
      question: "Where can I buy the book?",
      answer: `We are currently at the crowdsourcing stage. You can secure your future copy by backing SEWER SCUM on Kickstarter.`,
    },
    {
      question: "Is the book finished?",
      answer:
        "At the time of the Kickstarter launch, 99% of the book is written and thoroughly play tested, and ~70% of the art is finished. Content is subject to change as we continue playtesting while finishing up the art.",
    },
    {
      question: "Will there be a hardcover version?",
      answer:
        "While not the first RPG we’ve done, this is our first Kickstarter project. We want to start small, prompt, and affordable to get our game at as many tables as possible. We do hope to one day be able to expand this into a longer hard cover book.",
    },
  ],
  [
    {
      question: "Is it compatible with Mork Borg or Cy_Borg?",
      answer:
        "Both! Since a core concept of the setting is gonzo chaos from mutations, alien technology, and extra dimensional portals, it’s easy to mash up any genre’s content into SEWER SCUM.",
    },
    {
      question: "Can I release third party content?",
      answer:
        "Absolutely! Check out the Third party license in the Content section. Also, if you want to send us a copy we’d love to play your content too!",
    },

    {
      question: "What is Dice Cats?",
      answer:
        "Dice Cats is a small group of game designers (and a professional editor) who write and play games together and also have a shared love of cats.",
    },
  ],
  [
    {
      question: "What are SEWER SCUM’s inspirations?",
      answer:
        "80s and early 90s zines. Teenage Mutant Ninja Turtles. Frank Miller’s Batman and Daredevil. Mork Borg. Frontier Scum. Spider-Man. Too many game design blogs to list.",
    },
    {
      question: "Where can it be shipped?",
      answer:
        "Most locations on planet Earth. There are a few countries where shipping is tricky (more details on the Kickstarter), and extra dimensiontal shipping is prohibitively expensive.",
    },
    {
      question: "Will I get sick if I eat these pizzas?",
      answer:
        "Deadly sick? Probably not. Gagging sick? Definitely a possibility. If you make a pizza you rolled from the table, send us a pic!",
    },
  ],
];

export function Faqs() {
  return (
    <section
      id="faqs"
      aria-labelledby="faqs-title"
      className="py-0"
    >
      <div
        className="relative overflow-hidden py-20 sm:py-32 lg:pb-32 xl:pb-36"
        style={{
          backgroundImage: `url(${floppy})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#000000",
        }}
      >
        <Container>
          <div className="mx-auto max-w-2xl lg:mx-0 bg-black p-4 rounded-2xl">
            <h2
              id="faqs-title"
              className="text-3xl font-medium tracking-tight text-[#00FF00] pixeloid-sans"
            >
              Frequently asked questions
            </h2>
            <p className="mt-2 text-lg text-white">
              If you have anything else you want to ask,{" "}
              <a
                href="mailto:dicecatscontact@gmail.com"
                className="underline"
              >
                reach out to us
              </a>
              .
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:mt-20 lg:max-w-none lg:grid-cols-3"
          >
            {faqs.map((column, columnIndex) => (
              <li key={columnIndex}>
                <ul role="list" className="space-y-10 ">
                  {column.map((faq, faqIndex) => (
                    <li key={faqIndex} className="bg-black text-[#00FF00] p-4 rounded-2xl">
                      <h3 className="text-lg font-semibold leading-6 pixeloid-sans">
                        {faq.question}
                      </h3>
                      <p className="mt-4 text-sm text-white">{faq.answer}</p>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </Container>
      </div>
    </section>
  );
}
