import { Community } from './Components/Community'
import { Faqs } from './Components/Faqs'
import { Hero } from './Components/Hero'
import { Content } from './Components/Content'
import { PrimaryPreview } from './Components/PrimaryPreview'
import { Reviews } from './Components/Reviews'
import { SecondaryPreview } from './Components/SecondaryPreview'

export default function Home() {
  return (
    <>
      <Hero />
      <PrimaryPreview />
      <SecondaryPreview />
      <Content />
      <Faqs />
      <Community />
      <Reviews />
    </>
  )
}
