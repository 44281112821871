import clsx from "clsx";

import { Button } from "./Button";
import { Container } from "./Container";
import { SVGLogoNoText } from "./Logo";
import charGenExampleImage from "../images/decorations/char-gen-example.png";
import charSheetBlankImage from "../images/decorations/sewer_scum_blank_sheet.png";
import charSheetBlank from "../images/downloadable/sewer_scum_char_sheet.pdf";
import thirdPartyImage from "../images/decorations/sewer_scum_third_party_image.png";
import thirdParty from "../images/downloadable/sewer_scum_third_party.zip";

const plans = [
  {
    name: "Character Generator",
    description: "Generate random SEWER SCUM characters.",
    button: {
      label: "Generator Link",
      href: "/sewerscum/chargen",
    },
    image: charGenExampleImage,
  },
  {
    name: "Character Sheets",
    description: "Blank SEWER SCUM character sheet PDF.",
    button: {
      label: "Download",
      href: charSheetBlank,
      download: "sewer_scum_blank_sheet.pdf",
    },
    image: charSheetBlankImage,
  },
  {
    name: "Third Party License",
    description: "Third party license text and logos.",
    button: {
      label: "Download",
      href: thirdParty,
      download: "sewer_scum_third_party.zip",
    },
    image: thirdPartyImage,
  },
];

function Plan({ name, description, button, image }) {
  return (
    <section
      className={clsx(
        "flex flex-col overflow-hidden rounded-3xl p-6 shadow-lg shadow-gray-900/5",
        "bg-white"
      )}
    >
      <h3
        className={clsx(
          "flex items-center text-sm font-semibold",
          "text-gray-900"
        )}
      >
        <SVGLogoNoText color="#000000" size={32} className="h-10 w-auto" />
        <span className="ml-4">{name}</span>
      </h3>
      <p
        className={clsx(
          "relative mt-2 flex text-3xl tracking-tight",
          "text-gray-900"
        )}
      ></p>
      <p className={clsx("text-sm", "text-gray-700")}>{description}</p>
      <div className="order-last mt-6">
        <ul
          role="list"
          className={clsx(
            "-my-2 divide-y text-sm",
            "divide-gray-200 text-gray-700"
          )}
        >
          <img
            src={image}
            alt={name}
            style={{ height: "200px", objectFit: "contain", width: "100%" }}
          />
        </ul>
      </div>
      {button.download ? (
        <a
          href={button.href}
          download={button.download}
          className="mt-3 inline-block px-4 py-2 text-center text-white bg-gray-800 rounded-md"
          aria-label={name}
        >
          {button.label}
        </a>
      ) : (
        <Button
          href={button.href}
          color={"gray"}
          className="mt-3"
          aria-label={``}
        >
          {button.label}
        </Button>
      )}
    </section>
  );
}

export function Content() {
  return (
    <section
      id="content"
      aria-labelledby="content-title"
      className="border-t border-gray-200 bg-black py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <h2
            id="content-title"
            className="text-6xl font-medium tracking-tight text-white permanent-marker"
          >
            FREE CONTENT
          </h2>
          <p className="mt-2 text-lg text-gray-400">
            Check back for more free content after release including sample two free introductory adventures.
          </p>
        </div>

        <div className="flex justify-center">
          <div className="relative">
            <div
              aria-hidden="true"
              className={clsx(
                "pointer-events-none absolute inset-0 z-10 grid grid-cols-2 overflow-hidden rounded-lg bg-cyan-500 transition-all duration-300",

                "[clip-path:inset(0_50%_0_0)]"
              )}
            ></div>
          </div>
        </div>

        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-10 sm:mt-10 lg:max-w-none lg:grid-cols-3">
          {plans.map((plan) => (
            <Plan key={plan.name} {...plan} />
          ))}
        </div>
      </Container>
    </section>
  );
}
