import { Fragment, useEffect, useId, useRef, useState } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useDebouncedCallback } from "use-debounce";
import { CircleBackground } from "./CircleBackground";
import { Container } from "./Container";
import manhole from "../images/decorations/manhole_backdrop.png";

const previews = [
  {
    name: "Six new character classes",
    description:
      "Mondo Mutant, Jack-of-all-Mutants, Scrappy Mutant, Vigilante, Ninja, and Tech Wiz. Each class has a passive along with six special ability options.",
    screen: ClassScreen,
  },
  {
    name: "Dystopian 80s NYC",
    description:
      "Explore New York City's five boroughs, each ravenged in their own way from the chaos caused by alien technology.",
    screen: SettingsScreen,
  },
  {
    name: "Tons of new friends and foes",
    description:
      "Five prominent factions and over 40 new stat blocks, plus a system of tables and guidance to create your own.",
    screen: NPCScreen,
  },
];

function ClassScreen(props) {
  return (
    <div className="relative z-20">
      <p>Class Screenshot</p>
    </div>
  );
}

function SettingsScreen(props) {
  return (
    <div className="relative z-20">
      <p>Buroughs Screenshot</p>
    </div>
  );
}

function NPCScreen(props) {
  return (
    <div className="relative z-20">
      <p>NPM Faction Screenshot</p>
    </div>
  );
}

function usePrevious(value) {
  let ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function FeaturesDesktop() {
  let [changeCount, setChangeCount] = useState(0);
  let [selectedIndex, setSelectedIndex] = useState(0);
  let prevIndex = usePrevious(selectedIndex);
  let isForwards = prevIndex === undefined ? true : selectedIndex > prevIndex;

  let onChange = useDebouncedCallback(
    (selectedIndex) => {
      setSelectedIndex(selectedIndex);
      setChangeCount((changeCount) => changeCount + 1);
    },
    100,
    { leading: true }
  );

  return (
    <TabGroup
      className="grid grid-cols-12 items-center gap-8 lg:gap-16 xl:gap-24"
      selectedIndex={selectedIndex}
      onChange={onChange}
      vertical
    >
      <TabList className="relative z-10 order-last col-span-6 space-y-6">
        {previews.map((feature, featureIndex) => (
          <div
            key={feature.name}
            className="relative rounded-2xl transition-colors hover:bg-lime-700"
          >
            {featureIndex === selectedIndex && (
              <motion.div
                layoutId="activeBackground"
                className="absolute inset-0 bg-lime-700"
                initial={{ borderRadius: 16 }}
              />
            )}
            <div className="relative z-10 p-8">
              <h3 className="text-lg font-semibold text-white">
                <Tab className="text-left ui-not-focus-visible:outline-none">
                  <span className="absolute inset-0 rounded-2xl" />
                  {feature.name}
                </Tab>
              </h3>
              <p className="mt-2 text-sm text-white">{feature.description}</p>
            </div>
          </div>
        ))}
      </TabList>
      <div className="relative col-span-6">
        <div className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2 z-10 ml-[-8px] pointer-events-none">
          <CircleBackground color="#4d7c0f" className="animate-spin-slower" />
        </div>
        <div
          className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2 z-0"
          style={{ width: "635px", height: "635px" }}
        >
          <img
            src={manhole}
            alt="Manhole"
            style={{ width: "100%", height: "100%" }}
            className="block"
          />
        </div>

        <TabPanels as={Fragment}>
          <AnimatePresence initial={false} custom={{ isForwards, changeCount }}>
            {previews.map((feature, featureIndex) =>
              selectedIndex === featureIndex ? (
                <TabPanel
                  static
                  key={feature.name + changeCount}
                  className="col-start-1 row-start-1 flex focus:outline-offset-[32px] ui-not-focus-visible:outline-none"
                >
                  <feature.screen
                    animated
                    custom={{ isForwards, changeCount }}
                  />
                </TabPanel>
              ) : null
            )}
          </AnimatePresence>
        </TabPanels>
      </div>
    </TabGroup>
  );
}

function FeaturesMobile() {
  let [activeIndex, setActiveIndex] = useState(0);
  let slideContainerRef = useRef(null);
  let slideRefs = useRef([]);

  useEffect(() => {
    let observer = new window.IntersectionObserver(
      (entries) => {
        for (let entry of entries) {
          if (entry.isIntersecting && entry.target instanceof HTMLDivElement) {
            setActiveIndex(slideRefs.current.indexOf(entry.target));
            break;
          }
        }
      },
      {
        root: slideContainerRef.current,
        threshold: 0.6,
      }
    );

    for (let slide of slideRefs.current) {
      if (slide) {
        observer.observe(slide);
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [slideContainerRef, slideRefs]);

  return (
    <>
      <div className="-mb-4 flex snap-x snap-mandatory -space-x-4 overflow-x-auto overscroll-x-contain scroll-smooth pb-4 [scrollbar-width:none] sm:-space-x-6 [&::-webkit-scrollbar]:hidden">
        {previews.map((feature, featureIndex) => (
          <div
            key={featureIndex}
            ref={(ref) => ref && (slideRefs.current[featureIndex] = ref)}
            className="w-full flex-none snap-center px-4 sm:px-6"
          >
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800">
              <div className="absolute inset-0 flex items-center justify-center z-10 ml-[-8px]">
                <CircleBackground
                  color="#4d7c0f"
                  className="animate-spin-slower"
                />
              </div>
              <img
                src={manhole}
                alt="Manhole"
                style={{ transform: "scale(1.15)", height: "auto" }}
                className="block"
              />
              <div className="absolute inset-x-0 bottom-0 bg-gray-800/95 p-6 backdrop-blur sm:p-10">
                <h3 className="mt-6 text-sm font-semibold text-white sm:text-lg">
                  {feature.name}
                </h3>
                <p className="mt-2 text-sm text-gray-400">
                  {feature.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 flex justify-center gap-3">
        {previews.map((_, featureIndex) => (
          <button
            type="button"
            key={featureIndex}
            className={clsx(
              "relative h-0.5 w-4 rounded-full",
              featureIndex === activeIndex ? "bg-gray-300" : "bg-gray-500"
            )}
            aria-label={`Go to slide ${featureIndex + 1}`}
            onClick={() => {
              slideRefs.current[featureIndex].scrollIntoView({
                block: "nearest",
                inline: "nearest",
              });
            }}
          >
            <span className="absolute -inset-x-1.5 -inset-y-3" />
          </button>
        ))}
      </div>
    </>
  );
}

export function PrimaryPreview() {
  return (
    <section
      id="preview"
      aria-label="Features for investing all your money"
      className="bg-black py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-3xl">
          <h2 className="text-3xl font-medium tracking-tight text-white">
            Rules Light, Flavor Heavy.
          </h2>
          <p className="mt-2 text-lg text-gray-400">
            Play as a vigilante, ninja, tech wiz, or one of three mutant classes
            in a world where mutations, alien tech, and dimensional
            portals make compatibility with Mork Borg, Cy_Borg, and related content
            a breeze.
          </p>
        </div>
      </Container>
      <div className="mt-16 md:hidden">
        <FeaturesMobile />
      </div>
      <Container className="hidden md:mt-20 md:block">
        <FeaturesDesktop />
      </Container>
    </section>
  );
}
