import React from 'react';
import clsx from 'clsx';
import './styles/tailwind.css'; // Ensure Tailwind CSS or styles are imported
import Header from './Components/Header';
import Footer from './Components/Footer';

const RootLayout = ({ children }) => {
  return (
    <div className={clsx('bg-gray-50 antialiased')}>
      {/* Header can be part of the layout */}
      <Header />
      
      {/* Main content area */}
      <main className='flex-auto'>
        {children}
      </main>
      
      {/* Footer or other layout elements */}
      <Footer />
    </div>
  );
};

export default RootLayout;
