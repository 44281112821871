import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { useInView } from "framer-motion";
import { Container } from "./Container";
import crushedCars from "../images/backgrounds/crushed-cars.png";

const reviews = [
  {
    title: "More fun than Alf pogs.",
    body: "We had a BLAST! My mom said I was the best ninja in New York!",
    author: "Millhouse",
    rating: 5,
  },
  {
    title: "Death comes for us all",
    body: "Until then, play SEWER SCUM.",
    author: "HamatoYoshi",
    rating: 5,
  },
  {
    title: "Fun for all the senses.",
    body: "SEWER SCUM is so much fun that I can’t believe it’s actually legal.",
    author: "MattMurdock",
    rating: 5,
  },
  {
    title: "Women helped design this.",
    body: "This product doesn't hate women, and I don't hate this product.",
    author: "RiotGrrrl",
    rating: 5,
  },
  {
    title: "What?",
    body: "What are you talking about?",
    author: "MyNeighbor",
    rating: 5,
  },
  {
    title: "This human product is good.",
    body: "It brings other humans over which leads to more scratches and treats.",
    author: "MyCats",
    rating: 5,
  },
  {
    title: "The animals love it.",
    body: "Pretending to be anthropomorphic humans is cracking up all the animals.",
    author: "ZooManager",
    rating: 5,
  },
  {
    title: "Mondo RPG dudes.",
    body: "We used to get so bored down here, but now we play SEWER SCUM all the time.",
    author: "VoicesFromTheSewer",
    rating: 5,
  },
  {
    title: "I’m a SEWER SCUM kid.",
    body: "Enough nostalgia to fill a warehouse toy store.",
    author: "DoctorG.Raffe",
    rating: 5,
  },
  {
    title: "Nothing hard about it.",
    body: "I used to spend my nights in front of a computer, but now I spend them playing SEWER SCUM!",
    author: "TuringComplete",
    rating: 5,
  },
  {
    title: "Amazing design.",
    body: "This is literally the most well designed thing since Earth 2.",
    author: "Slartibartfast",
    rating: 5,
  },
  {
    title: "A hell of a city.",
    body: "Have you ever lived in the suburbs? It's sterile. It's nothing. It's wasting your life. Live in SEWER SCUM.",
    author: "EdwardKoch",
    rating: 5,
  },
  {
    title: "I’m proud of you.",
    body: "I don't know what this is, but great job honey!",
    author: "MyMom",
    rating: 5,
  },
  {
    title: "The book you deserve.",
    body: "This book is helping keep the streets safe from bordem. ",
    author: "BruceWayne",
    rating: 5,
  },
];

function StarIcon(props) {
  return (
    <svg viewBox="0 0 20 20" aria-hidden="true" {...props}>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  );
}

function StarRating({ rating }) {
  return (
    <div className="flex">
      {[...Array(5).keys()].map((index) => (
        <StarIcon
          key={index}
          className={clsx(
            "h-5 w-5",
            rating > index ? "fill-lime-400" : "fill-gray-300"
          )}
        />
      ))}
    </div>
  );
}

function Review({ title, body, author, rating, className, ...props }) {
  let animationDelay = useMemo(() => {
    let possibleAnimationDelays = [
      "0s",
      "0.1s",
      "0.2s",
      "0.3s",
      "0.4s",
      "0.5s",
    ];
    return possibleAnimationDelays[
      Math.floor(Math.random() * possibleAnimationDelays.length)
    ];
  }, []);

  return (
    <figure
      className={clsx(
        "animate-fade-in rounded-3xl bg-[#9895b4] p-6 opacity-0 shadow-md shadow-gray-900/5",
        className
      )}
      style={{ animationDelay }}
      {...props}
    >
      <blockquote className="text-black">
        <StarRating rating={rating} />
        <p className="mt-4 text-lg font-semibold leading-6 before:content-['“'] after:content-['”']">
          {title}
        </p>
        <p className="mt-3 text-base leading-7">{body}</p>
      </blockquote>
      <figcaption className="mt-3 text-sm text-black before:content-['–_']">
        {author}
      </figcaption>
    </figure>
  );
}

function splitArray(array, numParts) {
  let result = [];
  for (let i = 0; i < array.length; i++) {
    let index = i % numParts;
    if (!result[index]) {
      result[index] = [];
    }
    result[index].push(array[i]);
  }
  return result;
}

function ReviewColumn({ reviews, className, reviewClassName, msPerPixel = 0 }) {
  let columnRef = useRef(null);
  let [columnHeight, setColumnHeight] = useState(0);
  let duration = `${columnHeight * msPerPixel}ms`;

  useEffect(() => {
    if (!columnRef.current) {
      return;
    }

    let resizeObserver = new window.ResizeObserver(() => {
      setColumnHeight(columnRef.current?.offsetHeight ?? 0);
    });

    resizeObserver.observe(columnRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      ref={columnRef}
      className={clsx("animate-marquee space-y-8 py-4", className)}
      style={{ "--marquee-duration": duration }}
    >
      {reviews.concat(reviews).map((review, reviewIndex) => (
        <Review
          key={reviewIndex}
          aria-hidden={reviewIndex >= reviews.length}
          className={reviewClassName?.(reviewIndex % reviews.length)}
          {...review}
        />
      ))}
    </div>
  );
}

function ReviewGrid() {
  let containerRef = useRef(null);
  let isInView = useInView(containerRef, { once: true, amount: 0.4 });
  let columns = splitArray(reviews, 3);
  let column1 = columns[0];
  let column2 = columns[1];
  let column3 = splitArray(columns[2], 2);

  return (
    <div
      ref={containerRef}
      className="relative -mx-4 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 mt-10 md:grid-cols-2 lg:grid-cols-3"
    >
      {isInView && (
        <>
          <ReviewColumn
            reviews={[...column1, ...column3.flat(), ...column2]}
            reviewClassName={(reviewIndex) =>
              clsx(
                reviewIndex >= column1.length + column3[0].length &&
                  "md:hidden",
                reviewIndex >= column1.length && "lg:hidden"
              )
            }
            msPerPixel={10}
          />
          <ReviewColumn
            reviews={[...column2, ...column3[1]]}
            className="hidden md:block"
            reviewClassName={(reviewIndex) =>
              reviewIndex >= column2.length ? "lg:hidden" : ""
            }
            msPerPixel={15}
          />
          <ReviewColumn
            reviews={column3.flat()}
            className="hidden lg:block"
            msPerPixel={10}
          />
        </>
      )}
      <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-[#64427a]" />
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-[#64427a]" />
    </div>
  );
}

export function Reviews() {
  return (
    <section
      id="reviews"
      aria-labelledby="reviews-title"
      className="py-0"
    >
      <div
        className="relative overflow-hidden py-20 sm:py-32 lg:pb-32 xl:pb-36"
        style={{
          backgroundImage: `url(${crushedCars})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#000000",
        }}
      >
        <Container>
          <h2
            id="reviews-title"
            className="text-6xl font-medium tracking-tight text-[#8db334] sm:text-center double-feature"
          >
            Hear what everyone is saying about SeWER SCUM
          </h2>
          <ReviewGrid />
        </Container>
      </div>
    </section>
  );
}
