import { Button } from "./Button";
import { KSIcon } from "./Icons";
import discordIcon from "../images/logos/discordIcon.png";
import redditIcon from "../images/logos/redditIcon.png";
import youtubeIcon from "../images/logos/youtubeIcon.png";
import { CircleBackground } from "./CircleBackground";
import { Container } from "./Container";
import manhole from "../images/decorations/manhole_backdrop.png";
import joinOurCommunities from "../images/decorations/join-our-communities.png";

export function Community() {
  return (
    <section
      id="community"
      className="relative overflow-hidden bg-black py-20 sm:py-28"
    >
      <div className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2 z-10 ml-[-8px] pointer-events-none">
        <CircleBackground color="#4d7c0f" className="animate-spin-slower" />
      </div>
      <div
        className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2 z-0"
        style={{ width: "635px", height: "635px" }}
      >
        <img
          src={manhole}
          alt="Manhole"
          style={{ width: "100%", height: "100%" }}
          className="block ml-[-2.5rem] sm:ml-0"
        />
      </div>
      <Container className="relative z-20">
        <div className="mx-auto max-w-lg sm:text-center my-[-3rem]">
          <img
            src={joinOurCommunities}
            alt="Packed with content"
            className=""
          />
          <div className="mt-4 flex justify-center items-center">
            <a
              href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={discordIcon}
                alt="Discord"
                style={{ width: "10rem", height: "auto" }}
              />
            </a>
            <a
              href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-10"
            >
              <img
                src={redditIcon}
                alt="Reddit"
                style={{ width: "10rem", height: "auto" }}
              />
            </a>
            <a
              href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-10"
            >
              <img
                src={youtubeIcon}
                alt="YouTube"
                style={{ width: "10rem", height: "auto" }}
              />
            </a>
          </div>

          <div className="mt-8 flex justify-center">
            <Button
              href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
              variant="solid"
              color="lime"
            >
              <KSIcon />
              <span className="ml-2.5 text-white">Back this project</span>
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
}
