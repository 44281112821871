import { Container } from "./Container";
import pizzaCloseUp from "../images/backgrounds/pizza-close-up.png";

const previews = [
  {
    name: "42",
    description: "Class abilities across 6 classes plus a classless scum option.",
  },
  {
    name: "12+",
    description:
      "Setting tables including city encounters, vehicle mods, and d66 bodacious pizza toppings.",
  },
  {
    name: "300+",
    description:
      "‘80s themed items and equipment, including weapons, vehicles, and more.",
  },
  {
    name: "8",
    description:
      "Character trait tables for day jobs, styles, animal forms, obsessions, and more.",
  },
  {
    name: "60",
    description: "Episode ideas perfect for one-shots or campaign starters.",
  },
  {
    name: "2",
    description: "Dedicated pages full of optional rules and referee tips.",
  },
];

export function SecondaryPreview() {
  return (
    <section
      id="secondary-features"
      aria-label="Features for building a portfolio"
      className="py-0"
    >
      <div
        className="relative overflow-hidden py-20 sm:py-32 lg:pb-32 xl:pb-36"
        style={{
          backgroundImage: `url(${pizzaCloseUp})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#000000",
        }}
      >
        <Container>
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="font-semibold text-red-900 text-6xl text-center planet-benson rounded-2xl border-4 border-red-900 bg-opacity-70 bg-yellow-600">
              PACKED WITH CONTENT
            </h2>
            {/* <p className="mt-2 text-lg text-white">
              With typical market returns, you have to start young to secure
              your future. With Pocket, it’s never too late to build your nest
              egg.
            </p> */}
          </div>
          <ul
            role="list"
            className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 text-sm sm:mt-20 sm:grid-cols-2 md:gap-y-10 lg:max-w-none lg:grid-cols-3"
          >
            {previews.map((feature) => (
              <li
                key={feature.name}
                className="rounded-2xl border-4 border-red-900 bg-opacity-70 bg-yellow-600 p-4"
              >
                <h3
                  className="font-semibold text-red-900 text-6xl text-center planet-benson" 
                >
                  {feature.name}
                </h3>
                <p
                  className="mt-2 text-black text-2xl text-center permanent-marker"
                >
                  {feature.description}
                </p>
              </li>
            ))}
          </ul>
        </Container>
      </div>
    </section>
  );
}
