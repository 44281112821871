import React, { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { TextStyles, PositionStyles } from "./SewerScumCharGenStyles.jsx";
import {
  quirksTable,
  physicalDistinctionsTable,
  obsessionsTable,
  stylesTable,
  dayJobsTable,
  dayJobsItemsTable,
  armorTable,
  weaponTable,
  firstNamesTable,
  nicknameTable,
  lastNameTable,
  statLookupTable,
  classTable,
  classAbilityTables,
  classDescriptionTable,
  classStatsModifierTable,
  classHpTable,
  classLuckDieTable,
  classArmorTable,
  classWeaponTable,
  animalFormsTable,
} from "../../tables/tables-obfu.min.js";
import SewerScumCharSheet from "../../Assets/sewer_scum_blank_sheet.png";
import RollStat from "../../utilities/stat-roller.js";
import WeaponDisplay from "./weapons.jsx";
import GearDisplay from "./Gear.jsx";
import ComicButton from "../Buttons/ComicButton.jsx";
import "./SewerScumCharGen.css";
import "../../styles/print.css";

function SewerScumCharGen() {
  //Used to generate a new character without refreshing the page
  const [throwaway, setThrowaway] = useState([0]);

  const classValue = Math.floor(Math.random() * classTable.length);
  // const classValue = 1;

  const className = classTable[classValue];

  const classLuckDie = classLuckDieTable[classValue];

  const classAbilityValue = Math.floor(
    Math.random() * classAbilityTables[classValue].length
  );
  const classAbility = classAbilityTables[classValue][classAbilityValue];

  const classDescription = classDescriptionTable[classValue];

  const classArmorValue = Math.floor(
    Math.random() * classArmorTable[classValue]
  );

  const classWeaponValue = Math.floor(
    Math.random() * classWeaponTable[classValue]
  );

  const strengthStat = Math.max(
    parseInt(statLookupTable[RollStat()]) +
      classStatsModifierTable[classValue][0],
    -3
  );
  const agilityStat = Math.max(
    parseInt(statLookupTable[RollStat()]) +
      classStatsModifierTable[classValue][1],
    -3
  );
  const presenceStat = Math.max(
    parseInt(statLookupTable[RollStat()]) +
      classStatsModifierTable[classValue][2],
    -3
  );
  const toughnessStat = Math.max(
    parseInt(statLookupTable[RollStat()]) +
      classStatsModifierTable[classValue][3],
    -3
  );
  const knowledgeStat = Math.max(
    parseInt(statLookupTable[RollStat()]) +
      classStatsModifierTable[classValue][4],
    -3
  );

  const classHp = Math.max(
    Math.floor(Math.random() * classHpTable[classValue]) + toughnessStat,
    1
  );

  const luck = Math.floor(Math.random() * classLuckDieTable[classValue]) + 1;

  const startingCash = Math.floor(Math.random() * (120 - 20 + 1)) + 20; // 2d6 * 10

  const quirksValue = Math.floor(Math.random() * quirksTable.length);
  const physicalDistinctionsValue = Math.floor(
    Math.random() * physicalDistinctionsTable.length
  );
  const obsessionsValue = Math.floor(Math.random() * obsessionsTable.length);
  const stylesValue = Math.floor(Math.random() * stylesTable.length);
  const dayJobsValue = Math.floor(Math.random() * dayJobsTable.length);
  const firstNamesValue = Math.floor(Math.random() * firstNamesTable.length);
  const nicknameValue = Math.floor(Math.random() * nicknameTable.length);
  const lastNameValue = Math.floor(Math.random() * lastNameTable.length);
  const animalFormsValue = Math.floor(Math.random() * animalFormsTable.length);

  const quirks = quirksTable[quirksValue];
  const physicalDistinctions =
    physicalDistinctionsTable[physicalDistinctionsValue];
  const obsessions = obsessionsTable[obsessionsValue];
  const styles = stylesTable[stylesValue];
  const dayJobs = dayJobsTable[dayJobsValue];
  const dayJobsItems = dayJobsItemsTable[dayJobsValue];
  const animalForms = animalFormsTable[animalFormsValue];

  const armor = armorTable[classArmorValue];
  const weapon = weaponTable[classWeaponValue];
  const firstNames = firstNamesTable[firstNamesValue];
  const nickname = nicknameTable[nicknameValue];
  const lastName = lastNameTable[lastNameValue];

  //Import (material-ui) text styles
  const textStyleClasses = TextStyles();
  const positionStyleClasses = PositionStyles();

  function ArmorDisplay() {
    return (
      <div
        className={
          textStyleClasses.mediumText +
          " armor-text " +
          positionStyleClasses.Armor
        }
      >
        {armor}
      </div>
    );
  }

  const PrintDiv = () => {
    window.print();
  };

  const NewChar = () => {
    setThrowaway(Number(throwaway) + 1);
    console.log(throwaway);
  };

  return (
    <div className="sewer-scum-char-gen-container">
      <div className="no-print">
        <BrowserView>
          <ComicButton text="Save/Print" onClick={PrintDiv} />
          <ComicButton text="He's dead Jim" onClick={NewChar} />
        </BrowserView>
        <MobileView>
          <ComicButton text="Save/Print" onClick={PrintDiv}  className="mobile" />
          <ComicButton text="He's dead Jim" onClick={NewChar}  className="mobile"/>
        </MobileView>
      </div>
      <div className="container" id="print-content">
        <img src={SewerScumCharSheet} className="character-sheet" alt=""></img>

        <div
          className={
            textStyleClasses.largeText +
            " character-name-text " +
            positionStyleClasses.CharacterName
          }
        >
          <span>{firstNames + " “" + nickname + "” " + lastName}</span>
        </div>
        <div
          className={
            textStyleClasses.largeText +
            " class-name " +
            positionStyleClasses.ClassName
          }
        >
          {className}
          <span>
            {classValue in [0, 1, 2] && (
              <>
                {" ["}
                {animalForms}
                {"]"}
              </>
            )}
          </span>
        </div>
        <div
          className={
            textStyleClasses.mediumText +
            " class-features " +
            positionStyleClasses.ClassFeatures
          }
        >
          <div dangerouslySetInnerHTML={{ __html: classDescription }}></div>
          <br />
          <div dangerouslySetInnerHTML={{ __html: classAbility }}></div>
        </div>

        <div
          className={
            textStyleClasses.extraLargeText +
            " cash " +
            positionStyleClasses.Cash
          }
        >
          <strong>${startingCash}</strong>
        </div>

        <div
          className={
            textStyleClasses.largeText +
            " luck-die " +
            positionStyleClasses.LuckDie
          }
        >
          <strong>d{classLuckDie}</strong>
        </div>

        <div
          className={
            textStyleClasses.extraLargeText +
            " luck " +
            positionStyleClasses.Luck
          }
        >
          <strong>{luck}</strong>
        </div>

        <div
          className={
            textStyleClasses.extraLargeText +
            " hp " +
            positionStyleClasses.HitPoints
          }
        >
          <strong>{classHp}</strong>
        </div>

        <div
          className={
            textStyleClasses.extraLargeText +
            " strength " +
            positionStyleClasses.Strength
          }
        >
          {strengthStat >= 0 ? "+" + strengthStat : strengthStat}
        </div>
        <div
          className={
            textStyleClasses.extraLargeText +
            " agility " +
            positionStyleClasses.Agility
          }
        >
          {agilityStat >= 0 ? "+" + agilityStat : agilityStat}
        </div>
        <div
          className={
            textStyleClasses.extraLargeText +
            " presence " +
            positionStyleClasses.Presence
          }
        >
          {presenceStat >= 0 ? "+" + presenceStat : presenceStat}
        </div>
        <div
          className={
            textStyleClasses.extraLargeText +
            " toughness " +
            positionStyleClasses.Toughness
          }
        >
          {toughnessStat >= 0 ? "+" + toughnessStat : toughnessStat}
        </div>
        <div
          className={
            textStyleClasses.extraLargeText +
            " knowledge " +
            positionStyleClasses.Knowledge
          }
        >
          {knowledgeStat >= 0 ? "+" + knowledgeStat : knowledgeStat}
        </div>

        {WeaponDisplay(
          weapon,
          classValue,
          textStyleClasses.smallText,
          positionStyleClasses.Weapon
        )}

        <div className={textStyleClasses.smallText + " character-day-jobs"}>
          <strong>Day Job:</strong> {dayJobs}
        </div>
        <div className={textStyleClasses.smallText + " quirks"}>
          <strong>Quirk:</strong> {quirks}
        </div>
        <div className={textStyleClasses.smallText + " physical-distinctions"}>
          <strong>Distinction</strong> {physicalDistinctions}
        </div>
        <div className={textStyleClasses.smallText + " obsessions"}>
          <strong>Obsession:</strong> {obsessions}
        </div>
        <div className={textStyleClasses.smallText + " styles"}>
          <strong>Style:</strong> {styles}
        </div>
        {ArmorDisplay()}
        {GearDisplay(textStyleClasses.mediumText, dayJobsItems)}
      </div>
    </div>
  );
}

export default SewerScumCharGen;
