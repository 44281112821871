import { Link } from "react-router-dom";

import { Button } from "./Button";
import { Container } from "./Container";
import { TextField } from "./Fields";
import { LogoWhiteNoText } from "./Logo";
import { NavLinks } from "./NavLinks";
import { KSIcon } from "./Icons";

function QrCodeBorder(props) {
  return (
    <svg viewBox="0 0 96 96" fill="none" aria-hidden="true" {...props}>
      <path
        d="M1 17V9a8 8 0 0 1 8-8h8M95 17V9a8 8 0 0 0-8-8h-8M1 79v8a8 8 0 0 0 8 8h8M95 79v8a8 8 0 0 1-8 8h-8"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function Footer() {
  return (
    <footer className="bg-black">
      <Container>
        <div className="flex flex-col items-start justify-between gap-y-12 pb-6 pt-16 lg:flex-row lg:items-center lg:py-16">
          <div>
            <a
              href="/"
              aria-label="Home"
              className="flex items-center text-white"
            >
              <div className="flex items-center text-white">
                <LogoWhiteNoText
                  color="#ffffff"
                  size={40}
                  className="h-10 w-auto"
                />
                <div className="ml-4">
                  <p className="text-base font-semibold">Dice Cats</p>
                  <p className="mt-1 text-sm">Rules Light, Flavor Heavy</p>
                </div>
              </div>
            </a>
            <nav className="mt-11 flex gap-8">
              <NavLinks />
            </nav>
          </div>
          <div
            onClick={() => window.open("https://www.youtube.com/watch?v=dQw4w9WgXcQ", "_blank")}
            className="group relative block -mx-4 flex items-center self-stretch p-4 transition-colors hover:bg-gray-900 sm:self-auto sm:rounded-2xl lg:mx-0 lg:self-auto lg:p-6 cursor-pointer"
          >
            <div className="relative flex h-24 w-24 flex-none items-center justify-center">
              <QrCodeBorder className="absolute inset-0 h-full w-full stroke-gray-300 transition-colors group-hover:stroke-lime-700" />
              <KSIcon size={64} color="#4d7c0f" />
            </div>
            <div className="ml-8 lg:w-64">
              <p className="text-base font-semibold text-white">
                Back this project
              </p>
              <p className="mt-1 text-sm text-white">
                Fuel the mutant madness!
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center border-t border-gray-200 pb-12 pt-8 md:flex-row-reverse md:justify-between md:pt-6">
          <form className="flex w-full justify-center md:w-auto">
            <TextField
              type="email"
              aria-label="Email address"
              placeholder="Email address"
              autoComplete="email"
              required
              className="w-60 min-w-0 shrink"
            />
            <Button type="submit" color="lime" className="ml-4 flex-none">
              <span className="hidden lg:inline">Join our newsletter</span>
              <span className="lg:hidden">Join newsletter</span>
            </Button>
          </form>
          <p className="mt-6 text-sm text-white md:mt-0">
            &copy; Copyright {new Date().getFullYear()}. All rights reserved.
          </p>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
